import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MaterialModule } from '../../../../../../../libs/common/src'
import { TranslateModule } from '@ngx-translate/core'

export class DialogData {
  newTour: string
  title: string
}

@Component({
  selector: 'oeo-tour-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MaterialModule, TranslateModule],
  templateUrl: './tour-dialog.component.html',
  styleUrls: ['./tour-dialog.component.scss']
})
export class TourDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {}

  dismissDialog() {
    localStorage.setItem('dismissDialog', 'true')
  }
}
