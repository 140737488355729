<div class="content">
  <ng-contaner *ngIf="user; else LoadingUser">
    <mat-card
      class="unauthorized"
      *ngIf="user['http://allegion.com/claims/user_type'] !== 'external'; else SubmitAccessRequest"
    >
      <mat-card-content>
        <p [innerHtml]="'unauthorized' | translate"></p>
      </mat-card-content>
    </mat-card>
  </ng-contaner>
  <ng-template #LoadingUser>
    <div class="loading-user">
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>
  </ng-template>

  <ng-template #SubmitAccessRequest>
    <ng-container *ngTemplateOutlet="!submitted ? ExternalUnauthorized : RequestSubmitted"></ng-container>
  </ng-template>
  <ng-template #ExternalUnauthorized>
    <mat-card class="external-signup">
      <div class="padding s7 all">
        <h2>{{ 'LABEL.newUserSignUpRequest' | translate }}</h2>
        <mat-divider></mat-divider>
        <mat-card-content>
          <p [innerHtml]="'unauthorizedExternal' | translate" class="external-note"></p>
          <mat-divider></mat-divider>
          <h3>{{ 'LABEL.userInformation' | translate }}</h3>

          <form [formGroup]="userRequestForm" class="padding top s5">
            <span class="grid-2">
              <mat-form-field>
                <mat-label>{{ 'LABEL.firstName' | translate }}</mat-label>
                <input matInput id="first-name" formControlName="firstName" type="text" />
                <mat-error
                  *ngIf="userRequestForm.controls.firstName.touched && userRequestForm.controls.firstName.invalid"
                  >{{ 'ERRORS.requiredField' | translate }}</mat-error
                >
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'LABEL.lastName' | translate }}</mat-label>
                <input matInput id="last-name" formControlName="lastName" type="text" />
                <mat-error
                  *ngIf="userRequestForm.controls.lastName.touched && userRequestForm.controls.lastName.invalid"
                  >{{ 'ERRORS.requiredField' | translate }}</mat-error
                >
              </mat-form-field>
            </span>
            <span class="grid-2">
              <mat-form-field>
                <mat-label>{{ 'LABEL.email' | translate }}</mat-label>
                <input matInput id="email" formControlName="email" type="email" />
                <mat-error *ngIf="userRequestForm.controls.email.touched && userRequestForm.controls.email.invalid">{{
                  'ERRORS.requiredField' | translate
                }}</mat-error>
              </mat-form-field>
            </span>

            <h3>{{ 'LABEL.companyInformation' | translate }}</h3>

            <span class="grid-2">
              <mat-form-field>
                <mat-label>{{ 'LABEL.companyName' | translate }}</mat-label>
                <input matInput id="company-name" formControlName="companyName" type="text" />
                <mat-error
                  *ngIf="userRequestForm.controls.companyName.touched && userRequestForm.controls.companyName.invalid"
                  >{{ 'ERRORS.requiredField' | translate }}</mat-error
                >
              </mat-form-field>
            </span>

            <span class="grid-2">
              <mat-form-field>
                <mat-label>{{ 'LABEL.streetAddress' | translate }}</mat-label>
                <input matInput id="company-street" formControlName="companyStreetAddress" type="text" />
                <mat-error
                  *ngIf="
                    userRequestForm.controls.companyStreetAddress.touched &&
                    userRequestForm.controls.companyStreetAddress.invalid
                  "
                  >{{ 'ERRORS.requiredField' | translate }}</mat-error
                >
              </mat-form-field>
              <mat-form-field class="no-hint">
                <mat-label>{{ 'LABEL.secondaryStreetAddress' | translate }}</mat-label>
                <input
                  matInput
                  id="company-secondary-street-address"
                  formControlName="companySecondaryStreetAddress"
                  type="text"
                />
              </mat-form-field>
            </span>

            <span class="grid-2">
              <mat-form-field>
                <mat-label>{{ 'LABEL.city' | translate }}</mat-label>
                <input matInput id="company-street" formControlName="companyCity" type="text" />
                <mat-error
                  *ngIf="userRequestForm.controls.companyCity.touched && userRequestForm.controls.companyCity.invalid"
                  >{{ 'ERRORS.requiredField' | translate }}</mat-error
                >
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'LABEL.state' | translate }}</mat-label>
                <input matInput id="company-state" formControlName="companyState" type="text" />
                <mat-error
                  *ngIf="userRequestForm.controls.companyState.touched && userRequestForm.controls.companyState.invalid"
                  >{{ 'ERRORS.requiredField' | translate }}</mat-error
                >
              </mat-form-field>
            </span>
            <span class="grid-2">
              <mat-form-field>
                <mat-label>{{ 'LABEL.zipcode' | translate }}</mat-label>
                <input matInput id="zip-code" formControlName="companyZipCode" type="text" />
                <mat-error
                  *ngIf="
                    userRequestForm.controls.companyZipCode.touched && userRequestForm.controls.companyZipCode.invalid
                  "
                  >{{ 'ERRORS.requiredField' | translate }}</mat-error
                >
              </mat-form-field>
            </span>

            <h3>{{ 'LABEL.allegionSalesContact' | translate }}</h3>
            <span class="grid-2">
              <mat-form-field class="no-hint">
                <mat-label>{{ 'LABEL.firstName' | translate }}</mat-label>
                <input matInput id="sales-contact-first-name" formControlName="salesContactFirstName" type="text" />
              </mat-form-field>
              <mat-form-field class="no-hint">
                <mat-label>{{ 'LABEL.lastName' | translate }}</mat-label>
                <input matInput id="sales-contact-last-name" formControlName="salesContactLastName" type="text" />
              </mat-form-field>
            </span>
            <span class="grid-2">
              <mat-form-field class="no-hint">
                <mat-label>{{ 'LABEL.email' | translate }}</mat-label>
                <input matInput id="sales-contact-email" formControlName="salesContactEmail" type="email" />
              </mat-form-field>
            </span>
            <button mat-raised-button (click)="submit()" [disabled]="userRequestForm.invalid" color="primary">
              <span>{{ 'ACTIONS.submit' | translate }}</span>
            </button>
          </form>
        </mat-card-content>
      </div>
    </mat-card>
  </ng-template>

  <ng-template #RequestSubmitted>
    <mat-card class="submitted-card">
      <mat-card-title>
        {{
          (loading
            ? 'submittingAccessRequest'
            : successful
            ? 'accessRequestSubmitted'
            : 'ERRORS.accessRequestUnsuccessful'
          ) | translate
        }}
      </mat-card-title>
      <mat-card-content>
        <div class="content">
          <img *ngIf="loading" src="/assets/img/illustrations/submitting.svg" alt="" />
          <img *ngIf="!loading && successful" src="/assets/img/illustrations/success.svg" alt="" />
          <img *ngIf="!loading && !successful" src="/assets/img/illustrations/error.svg" alt="" />
          <p *ngIf="!loading" class="padding top bottom s8">
            {{ (successful ? 'accessRequestMessage' : 'accessRequestUnsuccessful') | translate }}
          </p>
          <mat-divider></mat-divider>
          <div class="submitted-actions">
            <button mat-stroked-button color="primary" class="submitted-button" (click)="openResources()">
              <span class="button-content">
                {{ 'ACTIONS.viewResourceCenter' | translate }}
                <mat-icon>open_in_new </mat-icon>
              </span>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>
</div>
