import { Injectable } from '@angular/core'
import { TourService } from 'ngx-ui-tour-md-menu'
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class TourGuideService {
  constructor(private readonly tourService: TourService, private router: Router) {}

  start(steps: IMdStepOption[]) {
    this.tourService.initialize(steps)
    this.tourService.start()
  }

  isDismissed() {
    return localStorage.getItem('dismissDialog') === 'true'
  }

  isOnCertainPage(page: string): boolean {
    // Get the current route URL
    const currentRoute = this.router.routerState.snapshot.url
    // Check if the current route matches the desired page URL
    return currentRoute === page
  }
}
