<h2 class="header">
  <img [src]="'/assets/img/oeo.svg'" alt="" class="logo" />
</h2>
<mat-dialog-content class="content" [ngClass]="{ adSystems: flags.adSystems, proExpress: flags.proExpress }">
  <h1>Getting Started</h1>
  <h3 class="subtitle-1">Select the type of Estimate you would like to create</h3>
  <div
    class="tile mat-elevation-z2"
    (click)="openNewEstimateDialog(estimateTypes.Standard, true)"
    ovtTrack
    ovtCategory="Estimate"
    ovtAction="Create Estiamate"
    [ovtProperties]="{ Location: 'New Estimate Dialog', EstimateType: 'Standard' }"
  >
    <div class="description">
      <h4>{{ estimateLabel | translate }}</h4>
      <p class="body-2">{{ estimateDescription | translate }}</p>
    </div>
    <img src="/assets/img/allegion.svg" alt="" />
  </div>

  <div
    *ngIf="flags.adSystems"
    class="tile mat-elevation-z2"
    (click)="openNewEstimateDialog(estimateTypes.ADSystems, false)"
    ovtTrack
    ovtCategory="Estimate"
    ovtAction="Create Estimate"
    [ovtProperties]="{ Location: 'New Estimate Dialog', EstimateType: 'AD Systems' }"
  >
    <div class="description">
      <h4>{{ 'adSystems' | translate }} {{ 'estimate' | translate }}</h4>
      <p class="body-2">{{ 'DESCRIPTION.adSystems' | translate }}</p>
    </div>
    <img src="/assets/img/illustrations/ad_systems.svg" alt="{{ 'adSystems' | translate }}" />
  </div>

  <div
    *ngIf="flags.stanley"
    class="tile mat-elevation-z2"
    (click)="openNewEstimateDialog(estimateTypes.Stanley, false)"
    ovtTrack
    ovtCategory="Estimate"
    ovtAction="Create Estimate"
    [ovtProperties]="{ Location: 'New Estimate Dialog', EstimateType: 'Stanley' }"
  >
    <div class="description">
      <h4>{{ 'TITLE.stanleyEstimate' | translate }}</h4>
      <p class="body-2">{{ 'DESCRIPTION.stanleyEstimate' | translate }}</p>
    </div>
    <img src="/assets/img/stanley.svg" alt="{{ 'TITLE.stanleyEstimate' | translate }}" />
  </div>
</mat-dialog-content>
