<div class="side-nav" [ngClass]="{ 'side-nav-expanded': !this.isCollapsed, 'side-nav-collapsed': this.isCollapsed }">
  <div class="logo-div">
    <img
      [ngClass]="{ collapsed: isCollapsed }"
      [src]="'/assets/img/oeo' + (isCollapsed ? '-condenced' : '') + '.svg'"
      alt=""
    />
  </div>
  <div
    class="side-nav-content"
    [ngClass]="{ collapsed: isCollapsed }"
    *ngIf="(customerService.unauthorized$ | async) === false; else EmptySideNav"
  >
    <div class="estimate-div" [ngClass]="{ collapsed: isCollapsed }">
      <button
        mat-stroked-button
        color="primary"
        class="new-button"
        attr.aria-label="{{ 'create' | translate }}"
        *ngIf="!isCollapsed"
        (click)="createNewDialog()"
        [disabled]="!authorized"
        ovtTrack
        ovtCategory="Estimate"
        ovtAction="Launch Create Estimate Dialog"
        [ovtProperties]="{ Location: 'SideNav' }"
      >
        <mat-icon svgIcon="add"></mat-icon>
        <span class="create-new" *ngIf="!isCollapsed">{{ 'ACTIONS.create' | translate }}</span>
      </button>
      <button
        mat-stroked-button
        color="primary"
        attr.aria-label="{{ 'actions.create' | translate }}"
        *ngIf="isCollapsed"
        class="new-button new-button-collapsed"
        (click)="createNewDialog()"
        [disabled]="!authorized"
        ovtTrack
        ovtCategory="Estimate"
        ovtAction="Launch Create Estimate Dialog"
        [ovtProperties]="{ Location: 'SideNav' }"
      >
        <mat-icon svgIcon="add"></mat-icon>
      </button>
    </div>
    <div class="menu-buttons">
      <ng-container *ngFor="let route of routes">
        <button
          *ngIf="route.show()"
          mat-button
          routerLink="/{{ route.path }}"
          routerLinkActive="active"
          attr.aria-label="{{ route.label | translate }}"
        >
          <div class="button-content">
            <mat-icon svgIcon="{{ route.svgIcon }}"></mat-icon>
            <span *ngIf="!isCollapsed">{{ route.label | translate }}</span>
          </div>
        </button>
      </ng-container>
    </div>
  </div>
  <ng-template #EmptySideNav>
    <div class="side-nav-content"></div>
  </ng-template>
  <div class="side-nav-footer">
    <button
      mat-button
      attr.aria-label="{{ 'ACTIONS.collapse' | translate }}"
      class="button"
      (click)="onToggleCollapse()"
    >
      <div class="button-content">
        <mat-icon [svgIcon]="isCollapsed ? 'arrow_forward' : 'arrow_back'"></mat-icon>
        <span *ngIf="!isCollapsed">{{ 'ACTIONS.collapse' | translate }}</span>
      </div>
    </button>
    <div class="user-profile-and-help">
      <button
        mat-button
        class="button"
        attr.aria-label="{{ 'ACTIONS.support' | translate }}"
        (click)="openHelpDialog()"
      >
        <div class="button-content">
          <mat-icon>help</mat-icon>
          <span *ngIf="!isCollapsed">{{ 'ACTIONS.support' | translate }}</span>
        </div>
      </button>
      <button
        mat-button
        class="button"
        attr.aria-label="{{ 'ACTIONS.collapse' | translate }}"
        *ngIf="user$ | async"
        (click)="openUserOptionsDialog()"
      >
        <div class="button-content">
          <span class="username">
            {{ (user$ | async)?.given_name?.substring(0, 1) + (user$ | async)?.family_name?.substring(0, 1) }}
          </span>
          <span *ngIf="!isCollapsed">{{ (user$ | async)?.given_name + ' ' + (user$ | async)?.family_name }}</span>
        </div>
      </button>
    </div>
  </div>
</div>
