import { Type } from '@angular/core'
import { OvtAction, OvtCategory } from './appInsights'
import { FunctionKeys } from './function-type'

export class Validators {
  min?: number
  max?: number
  required?: boolean
  minlength?: number
  maxlength?: number
  pattern?: RegExp
  type?: string
  disabled?: boolean
  step?: number
  directive?: string
  uniq?: boolean
  divisible?: boolean
  tableCellClassFns?: TableCellClass[]
}

export class TableButtonConfig<T> {
  defaultText?: string
  action!: FunctionKeys<T>
  icon?: string
  iconInput?: (row: any) => any
  materialIcon?: boolean
  ovtCategory?: OvtCategory
  ovtAction?: OvtAction
  ovtProperties?: { [index: string]: string }
  hidden?: boolean
  disabled?: boolean
  buttonClass?: string
  name?: string
  textClasses?: TableCellClass[]
  iconOnly?: boolean
  tourAnchor?: string
}

/**
 * Given a cell in a table, the text classes are classes to be applied to the button component based on the function passed
 */
export type TableCellClass = {
  shouldSetClass: (val: any) => any
  classToSet: string
  showWarning?: boolean
  warningMessage?: string
}

export class MenuItem<T> {
  label?: string
  action!: FunctionKeys<T>
  icon?: string
  materialIcon?: boolean
  ovtCategory?: OvtCategory
  ovtAction?: OvtAction
  ovtProperties?: { [index: string]: string }
  condition?: string
  cutsheet?: boolean
  hidden?: boolean
  disable?: boolean | ((val: any) => any)
  arguments?: any[]
  menuItemConditions?: {
    getLabel?: (val: any) => any
    getIcon?: (val: any) => any
    hide?: (val: any) => any
    disable?: (val: any) => any
  }
}

export interface TableIcon {
  icon: string
  /** Defaults to false, if referenceing a material icon rather than an svgIcon, set to true */
  materialIcon?: boolean
  color?: 'warn' | 'primary' | 'accent'
  ariaLabel?: string
  /** A property value or function that determines whether or not to show the icon */
  property: string | ((row: any) => any)
  tooltip?: string
  label?: string
  filterKey?: string
  /** Unless specified, the icon is filterable */
  filterable?: boolean
}

export class TableConfig<T> {
  columns: Column<T>[]
  name!: string
  pagination?: boolean // defaults to true
  paginationType?: 'front-end' | 'back-end' // defaults to 'front-end'
  hoverRows?: boolean // defaults to false
  checkboxes?: boolean // defaults to false
  radioButtons?: boolean // defaults to false
  canReorder?: boolean // defaults to false
  radioDisabled?: boolean // defaults to false
  expandable?: boolean // defaults to false
  stripes?: boolean // defaults to false
  hasHints?: boolean
  showRowNo?: boolean // defaults to false
  rowNoTitle?: string
  disableRowClick?: boolean = true
  constructor() {
    this.columns = []
  }
}

interface TableConfigHeaderIcon {
  icon: string
  tooltip?: string
  material: boolean
  headerClass?: string
  id?: string
  tourAnchor?: string
}

export class Column<T> {
  ariaLabel?: string
  buttonConfig?: TableButtonConfig<T>
  checkFn?: (val: any) => any
  color?: 'warn' | 'primary' | 'accent'
  component?:
    | 'ButtonComponent'
    | 'CrossReferenceInputComponent'
    | 'CurrencyComponent'
    | 'DateComponent'
    | 'DefaultComponent'
    | 'DropdownComponent'
    | 'IconComponent'
    | 'InputComponent'
    | 'MenuComponent'
    | 'ToggleComponent'
  dropdownOptions?: DropdownOption[]
  format?: string // date format
  header!: string
  headerIcon?: TableConfigHeaderIcon
  headerColumnSelectionMenu?: boolean
  icon?: string //  icon name
  icons?: TableIcon[]
  materialIcon?: boolean // defaults to false, if referenceing a material icon rather than an svgIcon, set to true
  menuItems?: MenuItem<T>[]
  name?: string
  notification?: boolean
  property?: string | ((row: any) => any)
  radioValue?: boolean
  secondaryColumnConfig?: Column<T>
  sortable?: boolean // defaults to true
  tableName?: string
  tooltip?: string | ((row: any) => any)
  type?: 'text' | 'date' | 'icon' | 'input' | 'currency' | 'menu' | 'toggle' | 'button' | 'dropdown' // defaults to text
  validators?: Validators
  value!: string | ((row: any) => any)
  wordBreak?: boolean // defaults to false
  chipClass?: (row: any) => string
  filterableColumn?: boolean = true
  filterValue?: string = ''
  hideIfNoData?: boolean
  hasData?: boolean
  visibleAlways?: boolean = false
  displayInFullRow?: boolean = false
  dynamicRowComponent?: Type<any>
  isBookmarked?: boolean = false
}

export class TableColumn<T> extends Column<T> {
  override secondaryColumnConfig?: Column<T>
  override dropdownOptions?: DropdownOption[]
  override icons?: TableIcon[]
  override filterableColumn?: boolean = true
  override filterValue?: string = ''
  override isBookmarked?: boolean = false

  constructor(column: Column<T> = new Column(), tableName: string = '') {
    super()
    this.property = column.value
    this.header = column.header
    this.headerIcon = column.headerIcon
    this.tableName = tableName
    this.sortable = column.sortable
    this.notification = column.notification
    this.ariaLabel = column.ariaLabel
    this.wordBreak = column.wordBreak
    this.secondaryColumnConfig = column.secondaryColumnConfig
    this.name = column.name
    this.validators = column.validators
    this.dropdownOptions = column.dropdownOptions
    this.icons = column.icons?.map((icon) => ({
      ...icon,
      filterable: Object.hasOwn(icon, 'filterable') ? icon.filterable : true
    }))
    this.tooltip = column.tooltip
    this.chipClass = column.chipClass
    this.filterValue = column.filterValue
    this.hideIfNoData = column.hideIfNoData
    this.hasData = column.hasData
    if (Object.keys(column).includes('filterableColumn')) {
      this.filterableColumn = column.filterableColumn
    }
    this.headerColumnSelectionMenu = column.headerColumnSelectionMenu
    this.visibleAlways = column.visibleAlways
    this.displayInFullRow = column.displayInFullRow
    this.dynamicRowComponent = column.dynamicRowComponent
    this.isBookmarked = column.isBookmarked
    switch (column.type) {
      case 'input':
        this.component =
          column.validators?.directive === 'crossReference' ? 'CrossReferenceInputComponent' : 'InputComponent'
        this.validators = column.validators
        this.sortable = column.sortable ?? false
        break
      case 'date':
        this.component = 'DateComponent'
        this.format = column.format
        break
      case 'menu':
        this.component = 'MenuComponent'
        this.menuItems = column.menuItems
        this.sortable = false
        this.tooltip = column.tooltip
        break
      case 'currency':
        this.component = 'CurrencyComponent'
        break
      case 'icon':
        this.component = 'IconComponent'
        this.icon = column.icon
        this.color = column.color
        this.materialIcon = column.materialIcon
        break
      case 'toggle':
        this.component = 'ToggleComponent'
        break
      case 'button':
        this.component = 'ButtonComponent'
        this.buttonConfig = column.buttonConfig
        break
      case 'dropdown':
        this.component = 'DropdownComponent'
        break
      default:
        this.component = 'DefaultComponent'
        this.tooltip = column.tooltip
    }
  }
}

export class DropdownOption {
  text!: string
  value!: string
}

export interface SelectColumn {
  name: string
  property: string
  show: boolean
  visibleAlways: boolean
}
