import { Component } from '@angular/core'
import { TourGuideService } from '../../../services/tour-guide/tour-guide.service'
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface'
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu'
import { TourDialogComponent } from '../tour-dialog/tour-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MaterialModule } from '../../../../../../../libs/common/src'
import oeoTours from '../../../../../../../apps/online-ordering/src/assets/oeoTours.json'
import { Router } from '@angular/router'

@Component({
  selector: 'oeo-tour-guide',
  standalone: true,
  imports: [TourMatMenuModule, CommonModule, MatButtonModule, MaterialModule],
  templateUrl: './tour-guide.component.html',
  styleUrls: ['./tour-guide.component.scss']
})
export class TourGuideComponent {
  showDialog: boolean
  steps = oeoTours
  currentTour: string
  currentSteps: IMdStepOption[]
  activateTour: boolean = false
  constructor(
    public router: Router,
    public tourGuideService: TourGuideService,
    public dialog: MatDialog,
    public tourService: TourService
  ) {}

  ngAfterViewInit(): void {
    this.setUpTours()
    this.showDialog =
      !this.tourGuideService.isDismissed() && this.tourGuideService.isOnCertainPage('/estimates') && this.activateTour
    if (this.showDialog) {
      this.openTourDialog()
    }
  }

  setUpTours(): void {
    oeoTours.forEach((tour: { tourName: string; steps: IMdStepOption[] }) => {
      if (tour.tourName == 'Bookmarks') {
        this.currentTour = tour.tourName
        this.currentSteps = tour.steps
      }
    })
  }

  openTourDialog(): void {
    const dialogRef = this.dialog.open(TourDialogComponent, {
      width: '367px',
      data: {
        /* you can pass data to the dialog if needed */
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'start') {
        this.tourGuideService.start(this.currentSteps)
      }
    })
  }
}
