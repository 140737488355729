<form class="content" [formGroup]="projectForm" (submit)="createProjectList()">
  <h1 class="header">
    {{ (data?.action === 'edit' ? 'TITLE.editProject' : 'TITLE.createProject') | translate }}
  </h1>
  <div mat-dialog-content>
    <mat-form-field class="no-hint full-width">
      <mat-label>{{ 'LABEL.projectName' | translate }}</mat-label>
      <input
        matInput
        #name
        formControlName="name"
        [attr.placeholder]="'LABEL.projectName' | translate"
        maxlength="50"
        (blur)="trim(name.value)"
      />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="secondary" type="button" (click)="cancel()">
      {{ 'ACTIONS.cancel' | translate }}
    </button>
    <button mat-raised-button type="submit" color="primary" [disabled]="!projectForm.controls.name.value || isSaving">
      {{ (data?.estimate ? 'ACTIONS.updateProject' : 'ACTIONS.createProject') | translate }}
    </button>
  </div>
</form>
