<mat-card>
  <mat-card-content>
    <div class="container">
      <h4 class="name">{{ 'LABEL.restrictedKeyAccount' | translate }}</h4>
      <div class="headers">
        <span class="oeo-label" id="locksmith">{{ 'LABEL.restrictedKeyAccount' | translate }}</span>
        <span class="oeo-label" id="description">{{ 'TABLE_HEADERS.description' | translate }}</span>
      </div>
      <form [formGroup]="lockSmithIdForm" autocomplete="off">
        <div formArrayName="locksmithNumbers">
          <div *ngFor="let id of numbers.controls; let i = index">
            <div [formGroupName]="i" class="row">
              <div class="input-group">
                <div class="input">
                  <mat-form-field>
                    <input
                      class="no-hint"
                      matInput
                      type="text"
                      placeholder="LXXXXX"
                      formControlName="locksmithNumber"
                      aria-labelledby="locksmith"
                      maxlength="6"
                      (blur)="onBlur(numbers.controls[i])"
                    />
                  </mat-form-field>
                </div>
                <div class="input">
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      formControlName="description"
                      placeholder="Enter Description"
                      aria-labelledby="description"
                      (blur)="onBlur(numbers.controls[i])"
                      maxlength="50"
                      class="no-hint"
                    />
                  </mat-form-field>
                </div>
                <div class="button">
                  <button
                    mat-raised-button
                    *ngIf="isLastRow(i)"
                    [attr.aria-label]="'ACTIONS.add' | translate"
                    color="primary"
                    (click)="addNumber()"
                    [disabled]="id.invalid || readOnly"
                  >
                    <mat-icon svgIcon="add"></mat-icon>
                  </button>
                  <button
                    mat-raised-button
                    *ngIf="!isLastRow(i)"
                    [attr.aria-label]="'ACTIONS.remove' | translate"
                    color="primary"
                    (click)="removeIdNumber(numbers.controls[i].value.id, i)"
                    [disabled]="readOnly"
                  >
                    <mat-icon svgIcon="close"></mat-icon>
                  </button>
                </div>
              </div>
              <mat-error *ngIf="numbers.controls[i].controls.locksmithNumber.errors?.duplicate">{{
                'ERRORS.locksmithIdUnique' | translate
              }}</mat-error>
              <mat-error *ngIf="numbers.controls[i].controls.locksmithNumber.errors?.pattern">{{
                'ERRORS.locksmithNumberValidation' | translate
              }}</mat-error>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
