import { Component, inject } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'
import { CommonModule } from '@angular/common'
import { map } from 'rxjs'
import { CreateZendeskTicketComponent } from '../create-zendesk-ticket/create-zendesk-ticket.component'
import { BaseFlagsService, FLAGS_SERVICE } from '../../injection-tokens/flags-service'
import { MaterialModule } from '../../material/material.module'

@Component({
  selector: 'lib-support-dialog',
  standalone: true,
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
  imports: [TranslateModule, MaterialModule, CommonModule]
})
export class SupportDialogComponent {
  dialog = inject<MatDialog>(MatDialog)
  dialogRef = inject<MatDialogRef<SupportDialogComponent>>(MatDialogRef<SupportDialogComponent>)
  flagsService = inject<BaseFlagsService>(FLAGS_SERVICE)
  adSystems = false

  resourseLink$ = this.flagsService.flags$.pipe(
    map((flags) => {
      this.adSystems = flags?.adSystems
      if (flags?.internal) return 'https://support.oeo.allegion.com/hc/en-us'
      if (flags?.isCanada) {
        return `https://support.oeo.allegion.com/hc/en-us/categories/${
          flags?.isDirect ? '8925915387028-Canada-Direct-Customers' : '9814521650452-Canada-Indirect-Customers'
        }`
      } else {
        return `https://support.oeo.allegion.com/hc/en-us/categories/${
          flags?.isDirect ? '8926716658324-U-S-Direct-Customers' : '9814499002900-U-S-Indirect-Customers'
        }`
      }
    })
  )

  openZenDeskHelpCenter() {
    this.dialogRef.close()
    this.dialog.open(CreateZendeskTicketComponent, {
      minWidth: '50vw',
      autoFocus: '#productLine'
    })
  }
}
