// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  authConfig: {
    clientID: '__authClientID__',
    domain: '__authDomain__',
    responseType: 'token id_token',
    audience: '__authAudience__',
    redirectUri: '__authRedirectURI__',
    logoutUrl: '__logoutUrl__',
    scope: 'openid profile'
  },
  onlineOrderingApiUrl: '__onlineOrderingApiUrl__',
  configurationApiUrl: '__configurationApiUrl__',
  linkC360Url: '__linkC360Url__',
  specialProgramUrl: '__specialProgramUrl__',
  appInsights: {
    instrumentationKey: '__appInsightsInstrumentationKey__',
    disableCorrelationHeaders: false,
    enableCorsCorrelation: true,
    correlationHeaderExcludedDomains: ['*.auth0.com'],
    correlationDomains: '__correlationDomains__'
  },
  accessEmail: '__accessEmail__',
  supportEmail: '__supportEmail__',
  featureFlags: {
    ieNotification: JSON.parse('__ieNotification__'),
    futurePrice: JSON.parse('__futurePrice__'),
    enablePhasedOrdering: JSON.parse('__enablePhasedOrdering__'),
    enableADSystemsInternal: JSON.parse('__enableADSystemsInternal__'),
    enableADSystemsExternal: JSON.parse('__enableADSystemsExternal__'),
    enableADSystemsHanding: JSON.parse('__enableADSystemsHanding__'),
    enableADSystemsCustomerQuoteDiscount: JSON.parse('__enableADSystemsCustomerQuoteDiscount__'),
    enableElevation: JSON.parse('__enableElevation__'),
    enableElevationPilotUsers: JSON.parse('__enableElevationPilotUsers__'),
    enableSteelcraft: JSON.parse('__enableSteelcraft__'),
    hideFrameElevationPricing: JSON.parse('__hideFrameElevationPricing__'),
    enableSpanish: JSON.parse('__enableSpanish__'),
    enableRPA: JSON.parse('__enableRPA__'),
    enableADSRepricing: JSON.parse('__enableADSRepricing__'),
    showHMSurchargeMessages: JSON.parse('__showHMSurchargeMessages__'),
    enableMultiBranch: JSON.parse('__enableMultiBranch__'),
    showPriceChangeMessage: JSON.parse('__showPriceChangeMessage__')
  },
  tracking: true,
  analyticsKey: '__analyticsKey__',
  gtmKey: '__gtmKey__',
  origin: '__origin__',
  elevations: {
    apiUrl: '__apiUrl__',
    blobUrl: '__blobUrl__'
  },
  flags: {
    speccy: false,
    hideFrameElevation: JSON.parse('__hideFrameElevation__'),
    hideDoorElevation: JSON.parse('__hideDoorElevation__')
  },
  sliFormTemplate: '__sliFormTemplate__'
}
